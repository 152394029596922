// JavaScript Document

// Scripts written by Jordan @ Weblinx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faBars            as fasFaBars            } from "@fortawesome/pro-solid-svg-icons/faBars";
import { faCaretDown       as fasFaCaretDown       } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faCaretLeft       as fasFaCaretLeft       } from "@fortawesome/pro-solid-svg-icons/faCaretLeft";
import { faCaretRight      as fasFaCaretRight      } from "@fortawesome/pro-solid-svg-icons/faCaretRight";
import { faCircleQuestion  as fasFaCircleQuestion  } from "@fortawesome/pro-solid-svg-icons/faCircleQuestion";
import { faCircleUser      as fasFaCircleUser      } from "@fortawesome/pro-solid-svg-icons/faCircleUser";
import { faClock           as fasFaClock           } from "@fortawesome/pro-solid-svg-icons/faClock";
import { faComment         as fasFaComment         } from "@fortawesome/pro-solid-svg-icons/faComment";
import { faFolder          as fasFaFolder          } from "@fortawesome/pro-solid-svg-icons/faFolder";
import { faMagnifyingGlass as fasFaMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons/faMagnifyingGlass";
import { faTag             as fasFaTag             } from "@fortawesome/pro-solid-svg-icons/faTag";
import { faXmark           as fasFaXmark           } from "@fortawesome/pro-solid-svg-icons/faXmark";
import { faAngleRight      as fasFaAngleRight      } from "@fortawesome/pro-solid-svg-icons/faAngleRight";
import { faAngleLeft       as fasFaAngleLeft       } from "@fortawesome/pro-solid-svg-icons/faAngleLeft";
import { faStar            as fasFaStar            } from "@fortawesome/pro-solid-svg-icons/faStar";

// social icons
import { faFacebook        as fabFaFacebook         } from "@fortawesome/free-brands-svg-icons/faFacebook";
import { faInstagram       as fabFaInstagram        } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faYoutube         as fabFaYouTube          } from "@fortawesome/free-brands-svg-icons/faYoutube";
import { faTiktok          as fabFaTiktok           } from "@fortawesome/free-brands-svg-icons/faTiktok";

/**
 * Add solid icons
 */
library.add(fasFaBars, fasFaCaretDown, fasFaCaretLeft, fasFaCaretRight, fasFaCircleQuestion, fasFaCircleUser, fasFaClock, fasFaComment, fasFaFolder, fasFaMagnifyingGlass, fasFaTag, fasFaXmark, fasFaAngleRight, fasFaAngleLeft, fasFaStar);

/**
 * Add social icons
 */
library.add(fabFaFacebook, fabFaInstagram, fabFaYouTube, fabFaTiktok);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
